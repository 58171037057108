@import "https://fonts.googleapis.com/css?family=Josefin+Sans:400,600";

html {
    
    &, body {
        width: 100%;
        height: 100%;
        padding:0;
        border: 0;
    }

    body {
        background: linear-gradient(90deg, #200933 75%, #3d0b43) no-repeat;
        background-color: transparent !important;
        background-image: linear-gradient(to bottom, #200933 75%, #3d0b43);
        background-size: auto 100vh;
        background-position: top;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        color: #000;
        mask-image: linear-gradient(to bottom, #0005 50%, #000 50%);
        mask-size: 100% 2px;

        &:before {
            background-image: repeating-linear-gradient(
                to bottom,
                transparent 0,
                transparent 2px,
                #fff 2px,
                #fff 4px
            );
            background-size: 100% 4px cover;
            transform-origin: 50% 50%;
            content: "";
            opacity: 0.02;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}


#neon84animation:after {
    content: '';
    height: 300px;
    width: 100%;
    display: block;
    background-image: linear-gradient(90deg, rgba(252, 25, 154, .1) 1px, rgba(0, 0, 0, 0) 1px), linear-gradient(0deg, rgba(252, 25, 154, .1) 1px, rgba(0, 0, 0, 0) 1px);
    background-position: bottom;
    background-repeat: repeat;
    background-size: 20px 20px;
    left: -25px;
    position: absolute;
    pointer-events: none;
    bottom: 0;
    transform: perspective(100px) rotateX(60deg);
    z-index: 0;
    animation: flight 2s infinite linear;
}

@keyframes flight {
    0% {
        transform: perspective(100px) rotateX(60deg) translateY(0px);
    }
    100% {
        transform: perspective(100px) rotateX(60deg) translateY(20px);
    }
}

#bTitle {
    font-family: "Josefin Sans", Helvetica, Arial, serif; font-weight: 600;
    text-align: center;
    color: #fff5f6;
    text-shadow: 0 0 2px #000, 0 0 10px #fc1f2c75, 0 0 5px #fc1f2c75, 0 0 25px #fc1f2c75;
}

#bLogo {
    text-align: center;

    .letmeglow {
        text-shadow: 0 0 2px #000, 0 0 10px #fc1f2c75, 0 0 5px #fc1f2c75, 0 0 25px #fc1f2c75;
        filter: drop-shadow(0 0 2px #000) drop-shadow(0 0 7px #8ff023);
    }
}

@media ( max-width: 770px ) {
    .letmeglow {
        
        width: 70vw;
    }
}